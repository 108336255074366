@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --brand-dark: #595B79;
  --brand-dark-rgb: 89, 91, 121;

  --brand-light: #F7F9FC;
  --brand-light-rgb: 247, 249, 252;

  --brand-text: #363849;
  --brand-text-rgb: 54, 56, 73;

  --brand-danger: #C07374;
  --brand-danger-rgb: 192, 115, 116;

  --brand-success: #B3CAA1;
  --brand-success-rgb: 179, 202, 161;

  --br-inputs: 50px;

  --full-height: 100%;

  --test-border: 1px solid #fff;
  --test-border-dotted: 0px dotted #fff;
}

html,
body {
  margin: 0px;
  font-family: Roboto, Arial, sans-serif;
  background-color: #fff;
  height: var(--full-height);
}

#root,
.App {
  height: var(--full-height);
}